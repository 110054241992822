import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root' // Cela rend le service disponible dans toute l'application.
})
export class SharedService {

    private env = "http://espaceclient.test/api"; // Récupère l'URL de base de l'API à partir de la configuration de l'environnement.

    constructor(private http: HttpClient) {}

    contact(data: any) {
        // Cette méthode envoie une requête POST à l'endpoint `/contact` avec les données du formulaire.
        return this.http.post(`${this.env}/send-email`, data, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
            }
        });
    }
}
