<div class="activity-home-area-2" style="background-image: url(assets/img/curage.jpg);">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="choice-home-content">
                    <h1>Curage et Démolition Intérieure</h1>
                    <p class="text-white">
                        DMT Recyclage vous accompagne dans vos projets de curage et démolition intérieure avec des services écoresponsables et de haute qualité.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container my-5">

    <section id="introduction-curage">
        <h2 class="section-title">Introduction</h2>
        <p>
            DMT Recyclage offre des services complets de curage et démolition intérieure pour bureaux et commerces, incluant le démontage de cloisons, plafonds suspendus, et l'élimination de planchers. Nous nous engageons à trier et recycler les matériaux, assurant une gestion responsable des déchets.
        </p>
    </section>

    <section id="services-curage">
        <h2 class="section-title">Services de Curage et Démolition</h2>
        <ul>
            <li><strong>Démontage et Démantèlement :</strong> Nos équipes qualifiées démontent et trient tous matériaux pour le recyclage.</li>
            <li><strong>Vidage des Locaux et Évacuation des Débris :</strong> Nous assurons un vidage complet et sécurisé des espaces, préparant ainsi pour rénovation ou reconstruction.</li>
            <li><strong>Petites Démolitions :</strong> Conduites avec respect des normes de sécurité et de l'environnement.</li>
        </ul>
    </section>

    <section id="gestion-recyclage">
        <h2 class="section-title">Gestion des Matériaux et Recyclage</h2>
        <ul>
            <li><strong>Tri et Séparation :</strong> Nous séparons les matières valorisables des déchets, favorisant le recyclage.</li>
            <li><strong>Recyclage et Valorisation Énergétique :</strong> Les matériaux récupérés sont recyclés via des partenaires certifiés; les résidus non recyclables sont valorisés énergétiquement.</li>
        </ul>
    </section>

    <section id="tracabilite-transparence">
        <h2 class="section-title">Traçabilité et Transparence</h2>
        <ul>
            <li><strong>Suivi des Matériaux :</strong> Nous offrons une traçabilité complète de la collecte au recyclage final via une plateforme digitale.</li>
            <li><strong>Rapports Personnalisés :</strong> Nous fournissons des rapports détaillés sur les déchets traités et les impacts environnementaux.</li>
        </ul>
    </section>

    <section id="choisir-dmt">
        <h2 class="section-title">Pourquoi Choisir DMT Recyclage ?</h2>
        <p>
            <strong>Expertise et Engagement :</strong> Services de qualité par des professionnels expérimentés, avec un engagement ferme envers la réduction des déchets et le zéro déchet.
        </p>
    </section>

    <section id="contact-curage">
        <h2 class="section-title">Contactez-Nous</h2>
        <p>
            Pour des services de curage et démolition écoresponsables, contactez DMT Recyclage pour un devis personnalisé.
        </p>
    </section>
</div>
